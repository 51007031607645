'use client'

import { Trans, useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { Button, Divider, Link, LoadingDots, Paragraph, Stack } from '@qasa/qds-ui'
import styled from '@emotion/styled'
import { useSearchParams } from 'next/navigation'

import { Link as RouterLink, useRouter } from '../../../vendor/next'
import { SectionHeading, SectionBody } from '../components'
import { useAuthContext } from '../../../context/auth-context'
import { BRAND_TOGGLES } from '../../../config/toggles'
import { getPath } from '../../../routing/get-path'
import { getP1AccessToken } from '../../../helpers/p1-auth'
import {
  DotcomPlatformSelectorDialog,
  shouldDisplayDotcomPlatformSelectorDialog,
} from '../../../feature-modules/dotcom-platform-selector-dialog/dotcom-platform-selector-dialog'
import type { SchibstedSSOMarket } from '../../../config/index.types'
import { LinkButtonV2 } from '../../../ui-shared/_core/link-button-v2'
import { SchibstedSLogo } from '../../../assets/logos/schibsted/schibsted-s-logo'
import { useSchibstedLogin } from '../schibsted-login/use-schibsted-login'
import { SchibstedSelector } from '../schibsted-login/schibsted-selector'
import { getAfterLoginParametersFromSearch } from '../../../helpers/auth-callback.utils'
import { MetaTags } from '../../meta-tags'

import { LoginForm } from './login-form'

const ContentWrapper = styled(Stack)({
  minHeight: 300,
})

const CenteredButton = styled(Button)({
  alignSelf: 'center',
})
const CenteredLinkButton = styled(LinkButtonV2)({
  alignSelf: 'center',
})
export function Login() {
  const { t: tPageTitle } = useTranslation('page_titles')
  const [isDisplayingSchibstedSelector, setIsDisplayingSchibstedSelector] = useState(false)
  const { t } = useTranslation('authentication_flow')
  const { isAuthenticated } = useAuthContext()
  const { handleSchibstedLogin, isLoading: isSchibstedLoginLoading } = useSchibstedLogin()
  const searchParams = useSearchParams()
  const search = searchParams.toString() ? `?${searchParams.toString()}` : ''

  const router = useRouter()
  const { availableLoginMethods } = BRAND_TOGGLES

  useEffect(() => {
    if (!isAuthenticated) {
      return
    }
    const isNativeApp = window?.isNativeApp

    /**
     * Redirect to /auth-callback if there is any after-login action, or to /messages if
     * user is currently on the /login page.
     * Otherwise stay on the current path.
     */

    const accessToken = getP1AccessToken()

    if (accessToken && isNativeApp && window?.postAccessToken) {
      window.postAccessToken({ accessToken })
    }

    if (isNativeApp) {
      return
    }

    const { returnTo } = getAfterLoginParametersFromSearch(window.location.search)

    if (!returnTo) {
      router.replace(getPath('messages'))
      return
    }

    router.replace(returnTo)
  }, [isAuthenticated, router])

  const schibstedAuthMethods = availableLoginMethods.filter((method): method is SchibstedSSOMarket =>
    ['schibsted_fi', 'schibsted_se'].includes(method),
  )
  const singleSchibstedAuthMethod = schibstedAuthMethods.length === 1 ? schibstedAuthMethods[0] : null
  const hasSingleSchibstedLoginMethod = singleSchibstedAuthMethod && availableLoginMethods.length === 1
  /**
   * If there is only one Schibsted auth method we want to redirect user to login
   * without having to press a button.
   */
  useEffect(() => {
    if (hasSingleSchibstedLoginMethod) {
      handleSchibstedLogin({ market: singleSchibstedAuthMethod })
    }
  }, [hasSingleSchibstedLoginMethod, handleSchibstedLogin, singleSchibstedAuthMethod])

  if (isAuthenticated || isSchibstedLoginLoading) {
    return (
      <ContentWrapper alignItems="center" justifyContent="center">
        <LoadingDots />
      </ContentWrapper>
    )
  }

  if (shouldDisplayDotcomPlatformSelectorDialog()) {
    return <DotcomPlatformSelectorDialog />
  }

  if (isDisplayingSchibstedSelector) {
    return <SchibstedSelector onBack={() => setIsDisplayingSchibstedSelector(false)} />
  }

  const handleSchibstedButtonClick = () => {
    /**
     * NOTE: If there is only a single Schibsted method available, we login directly with that method.
     * Otherwise there are multiple methods, we need to show a selection dialog.
     */
    if (singleSchibstedAuthMethod) {
      handleSchibstedLogin({ market: singleSchibstedAuthMethod })
    } else {
      setIsDisplayingSchibstedSelector(true)
    }
  }

  const hasSchibstedAuthMethods = schibstedAuthMethods.length >= 1
  return (
    <>
      <MetaTags title={tPageTitle('login')} />
      <SectionHeading>{t('login.heading')}</SectionHeading>
      <SectionBody>
        <Stack divider={<Divider />} gap={'6x'}>
          <Stack gap="4x">
            {availableLoginMethods.map((method) => {
              switch (method) {
                case 'one_time_password':
                  return (
                    <CenteredLinkButton
                      variant="tertiary"
                      href={`${getPath('otpLogin')}${search}`}
                      key={method}
                    >
                      {t('login_with_email')}
                    </CenteredLinkButton>
                  )
                case 'qasa':
                  return <LoginForm key={method} />
                default:
                  return null
              }
            })}
            {hasSchibstedAuthMethods && (
              <CenteredButton
                variant="tertiary"
                iconLeft={SchibstedSLogo}
                onClick={handleSchibstedButtonClick}
              >
                {t('login_with_schibsted')}
              </CenteredButton>
            )}
          </Stack>
          <Paragraph textAlign="center">
            <Trans
              t={t}
              i18nKey={'signup_link_text'}
              components={[<Link key="0" as={RouterLink} href={'/signup' + search} />]}
            />
          </Paragraph>
        </Stack>
      </SectionBody>
    </>
  )
}
