import { RadioGroup, Stack, Button, IconButton, ArrowLeftIcon, LoadingDots } from '@qasa/qds-ui'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

import type { SchibstedSSOMarket } from '../../../config/index.types'
import { BRAND_TOGGLES } from '../../../config/toggles'
import { SectionHeading, SectionBody, SectionFooter } from '../components'

import { useSchibstedLogin } from './use-schibsted-login'

const ContentWrapper = styled(Stack)({
  minHeight: 300,
})

export function SchibstedSelector({ onBack }: { onBack: () => void }) {
  const { t } = useTranslation('authentication_flow', { keyPrefix: 'schibsted_login' })
  const [tCommon] = useTranslation('commons')
  const { availableLoginMethods } = BRAND_TOGGLES
  const schibstedAuthMethods = availableLoginMethods.filter((method): method is SchibstedSSOMarket =>
    ['schibsted_fi', 'schibsted_se'].includes(method),
  )
  const [selectedMethod, setSelectedMethod] = useState<SchibstedSSOMarket>(schibstedAuthMethods[0])
  const { handleSchibstedLogin, isLoading } = useSchibstedLogin()

  if (isLoading) {
    return (
      <ContentWrapper alignItems="center" justifyContent="center">
        <LoadingDots />
      </ContentWrapper>
    )
  }

  return (
    <>
      <SectionHeading>{t('heading')}</SectionHeading>
      <SectionBody>
        <RadioGroup
          value={selectedMethod}
          onValueChange={(newValue) => setSelectedMethod(newValue as SchibstedSSOMarket)}
        >
          <Stack gap="2x">
            {schibstedAuthMethods.map((method) => (
              <RadioGroup.Card key={method} value={method} label={t('login_with', { context: method })} />
            ))}
          </Stack>
        </RadioGroup>
      </SectionBody>
      <SectionFooter>
        <Stack justifyContent={'space-between'} direction={'row-reverse'} style={{ width: '100%' }}>
          <Button onClick={() => handleSchibstedLogin({ market: selectedMethod })}>
            {tCommon('continue')}
          </Button>
          <IconButton
            type="button"
            icon={ArrowLeftIcon}
            variant="tertiary"
            label={tCommon('back')}
            onClick={onBack}
          />
        </Stack>
      </SectionFooter>
    </>
  )
}
