import { useCallback, useState } from 'react'

import { login as schibstedLogin } from '../../../vendor/schibsted-identity-client'
import type { SchibstedSSOMarket } from '../../../config/index.types'
import { useAuthContext } from '../../../context/auth-context'
import { getAfterLoginParametersFromSearch } from '../../../helpers/auth-callback.utils'
import { encodeAuthCallbackState } from '../../../feature-modules/auth/auth-callback-state'

export function useSchibstedLogin() {
  const [isLoading, setIsLoading] = useState(false)
  const { isAuthenticated } = useAuthContext()

  const handleSchibstedLogin = useCallback(
    ({ market }: { market: SchibstedSSOMarket }) => {
      setIsLoading(true)
      const decodedState = getAfterLoginParametersFromSearch(window.location.search)

      const encodedStateWithSchibstedMarket = encodeAuthCallbackState({
        ...decodedState,
        provider: market,
      })

      if (!isAuthenticated) {
        schibstedLogin({
          state: encodedStateWithSchibstedMarket,
          market,
        })
      }
    },
    [isAuthenticated],
  )

  return { handleSchibstedLogin, isLoading }
}
