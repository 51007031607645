import { parse } from 'query-string'

import type { AuthCallbackState } from '../feature-modules/auth/auth-callback-state'
import { decodeAuthCallbackState } from '../feature-modules/auth/auth-callback-state'

export const getAfterLoginParametersFromSearch = (search: string): AuthCallbackState => {
  const { state } = parse(search)

  if (!state || typeof state !== 'string') {
    return {}
  }

  return decodeAuthCallbackState(state)
}
